"use client";

import { useSession } from "next-auth/react";

import { env } from "~/env.mjs";
import { useStoreInfo } from "~/providers/StoreInfoProvider";
import { useSubscriptionSummary } from "~/providers/SubscriptionSummaryProvider";
import Amplitude from "./Amplitude";
import Dynatrace from "./Dynatrace";
import Hotjar from "./Hotjar";
import Satismeter from "./Satismeter";
import Userflow from "./Userflow";

const {
  NEXT_PUBLIC_AMPLITUDE_API_KEY,
  NEXT_PUBLIC_HOTJAR_SITE_ID,
  NEXT_PUBLIC_HOTJAR_VERSION,
  NEXT_PUBLIC_SATISMETER_WRITE_KEY,
  NEXT_PUBLIC_USERFLOW_TOKEN,
} = env;

const AMPLITUDE_ENABLED = !!NEXT_PUBLIC_AMPLITUDE_API_KEY;
const HOTJAR_ENABLED =
  !!NEXT_PUBLIC_HOTJAR_SITE_ID && !!NEXT_PUBLIC_HOTJAR_VERSION;
const SATISMETER_ENABLED = !!NEXT_PUBLIC_SATISMETER_WRITE_KEY;
const USERFLOW_ENABLED = !!NEXT_PUBLIC_USERFLOW_TOKEN;

export default function ExternalServices() {
  const storeInfo = useStoreInfo();
  const { tracking, hasLegacySubscription } = useSubscriptionSummary();
  const session = useSession();

  if (
    session.status !== "authenticated" ||
    !session.data ||
    !storeInfo ||
    !tracking
  ) {
    return null;
  }

  const { user } = session.data;

  return (
    <>
      <Dynatrace user={user} />

      {HOTJAR_ENABLED && (
        <Hotjar
          siteId={NEXT_PUBLIC_HOTJAR_SITE_ID}
          version={NEXT_PUBLIC_HOTJAR_VERSION}
          userId={user.id}
        />
      )}

      {USERFLOW_ENABLED && (
        <Userflow
          userflowToken={NEXT_PUBLIC_USERFLOW_TOKEN}
          userId={user.id}
          storeInfo={storeInfo}
          hasLegacySubscription={hasLegacySubscription}
        />
      )}

      {SATISMETER_ENABLED && (
        <Satismeter
          writeKey={NEXT_PUBLIC_SATISMETER_WRITE_KEY}
          storeInfo={storeInfo}
          tracking={tracking}
        />
      )}

      {AMPLITUDE_ENABLED && (
        <Amplitude
          apiKey={NEXT_PUBLIC_AMPLITUDE_API_KEY}
          storeInfo={storeInfo}
          tracking={tracking}
          hasLegacySubscription={hasLegacySubscription}
        />
      )}
    </>
  );
}

"use client";

import { useEffect } from "react";
import { signOut, useSession } from "next-auth/react";

import { clearUserSessionData } from "~/helpers";

export default function SessionCheck() {
  const session = useSession();

  // This prevents cases where the session has expired or the session cookie has been deleted.
  // In this particular case, the user is logged out and automatically redirected to the login page.
  // Another option could be to display a more specific message
  // with a call to action to prompt them to authenticate again.
  useEffect(() => {
    if (session.status === "unauthenticated") {
      clearUserSessionData();
      signOut();
    }
  }, [session.status]);

  return null;
}

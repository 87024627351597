import { useMemo } from "react";
import { useSession } from "next-auth/react";
import { UAParser } from "ua-parser-js";

import logger from "@offline/logger/logger";

import { useStoreInfo } from "../StoreInfoProvider";
import { isBrowserSupported, isOSSupported } from "./helpers";

const serializeUSBDevice = (device?: USBDevice) => {
  if (!device) {
    return undefined;
  }

  return {
    deviceName: device.productName,
    vendorId: device.vendorId,
    productId: device.productId,
    manufacturerName: device.manufacturerName,
    serialNumber: device.serialNumber,
    deviceClass: device.deviceClass,
    deviceSubclass: device.deviceSubclass,
    deviceProtocol: device.deviceProtocol,
    configurations: device.configurations.map((config) => ({
      configurationValue: config.configurationValue,
      interfaces: config.interfaces.map((iface) => ({
        interfaceNumber: iface.interfaceNumber,
        alternateSetting: iface.alternate.alternateSetting,
        classCode: iface.alternate.interfaceClass,
        subclassCode: iface.alternate.interfaceSubclass,
        protocolCode: iface.alternate.interfaceProtocol,
        endpoints: iface.alternate.endpoints.map((endpoint) => ({
          endpointNumber: endpoint.endpointNumber,
          direction: endpoint.direction,
          type: endpoint.type,
          packetSize: endpoint.packetSize,
        })),
      })),
    })),
  };
};

const getData = (device?: USBDevice) => {
  const ua = new UAParser();
  const data = {
    ...ua.getResult(),
    osSupportsWebUSB: isOSSupported(),
    browserSupportsWebUSB: isBrowserSupported(),
    device: serializeUSBDevice(device),
  };

  return data;
};

export default function usePrinterLogger(device?: USBDevice) {
  const storeInfo = useStoreInfo();
  const session = useSession();

  const _logger = useMemo(() => {
    const data = getData(device);

    return logger.child({
      context: { storeId: storeInfo.id, userId: session.data?.user.id, data },
    });
  }, [device, session.data?.user.id, storeInfo.id]);

  return _logger;
}

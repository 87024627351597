"use client";

import { useEffect } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { useLocale } from "next-intl";

import { getLocaleFieldValue } from "~/helpers/i18n";
import { type PlanSummary, type StoreInfo } from "~/types";

const AMPLITUDE_ID_MINIMUM_LENGTH = 5;

interface Props {
  apiKey: string;
  storeInfo: StoreInfo;
  tracking: PlanSummary["tracking"];
  hasLegacySubscription: boolean;
}

export default function Amplitude({
  apiKey,
  storeInfo,
  tracking,
  hasLegacySubscription,
}: Props) {
  const locale = useLocale();
  const { id, country, name, email, type, createdAt } = storeInfo;
  const { subscriptionCategory } = tracking;
  const storeName = getLocaleFieldValue(name, locale);

  useEffect(() => {
    amplitude.init(apiKey, { defaultTracking: false });
  }, [apiKey]);

  useEffect(() => {
    amplitude.setUserId(String(id).padStart(AMPLITUDE_ID_MINIMUM_LENGTH, "0"));

    const identifyEvent = new amplitude.Identify();
    identifyEvent.set("name", storeName);
    identifyEvent.set("country", country);
    identifyEvent.set("email", email);
    identifyEvent.set("vertical", type);
    identifyEvent.set("created_at", createdAt);
    identifyEvent.set("has_addon_model", !hasLegacySubscription);

    if (subscriptionCategory) {
      identifyEvent.set("plan", subscriptionCategory);
    }

    amplitude.identify(identifyEvent);
  }, [
    country,
    email,
    id,
    locale,
    name,
    storeName,
    type,
    subscriptionCategory,
    createdAt,
    hasLegacySubscription,
  ]);

  return null;
}

"use client";

import { useEffect, type PropsWithChildren } from "react";

import useSelectedLocation from "~/hooks/useSelectedLocation";
import SelectLocationModalWidget from "~/widgets/SelectLocationModalWidget";

function SelectLocationWrapper({ children }: Readonly<PropsWithChildren>) {
  const {
    hasMultiCD,
    hasValidOriginLocationSelected,
    saveLocations,
    originLocation,
  } = useSelectedLocation();

  useEffect(() => {
    const originLocationId = originLocation?.id;
    if (!hasMultiCD && !!originLocationId) {
      saveLocations({ originLocationId });
    }
  }, [hasMultiCD, originLocation?.id, saveLocations]);

  if (hasMultiCD && !hasValidOriginLocationSelected) {
    return <SelectLocationModalWidget />;
  }

  return children;
}

export default SelectLocationWrapper;

"use client";

import { useSearchParams } from "next/navigation";
import { signIn } from "next-auth/react";
import { useTranslations } from "next-intl";

import { Button } from "~/components";
import { clearUserSessionData } from "~/helpers";

const SignInButton = () => {
  const t = useTranslations("page.home");
  const searchParams = useSearchParams();

  const callbackUrl = searchParams.get("callbackUrl");
  const signInOptions = {
    callbackUrl: callbackUrl || "/cart",
  };

  const handleButtonClick = () => {
    clearUserSessionData();
    signIn("tiendanube", signInOptions);
  };

  return (
    <Button
      data-testid="sign-in-button"
      variant="primary"
      onClick={handleButtonClick}
    >
      {t("buttons.signIn")}
    </Button>
  );
};

export default SignInButton;

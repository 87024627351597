import { useEffect } from "react";
import userflow from "userflow.js";

import { type StoreInfo } from "~/types";

interface Props {
  userId: string;
  userflowToken: string;
  storeInfo: StoreInfo;
  hasLegacySubscription: boolean;
}

export default function Userflow({
  userflowToken,
  storeInfo,
  userId,
  hasLegacySubscription,
}: Props): null {
  const {
    userConfig: { roles, firstName, lastName, email },
  } = storeInfo;

  useEffect(() => {
    userflow.init(userflowToken);
    userflow.identify(email, {
      userId,
      email,
      name: getFullName(firstName, lastName),
      posLastSeen: new Date().toISOString(),
      posUserPermissions: roles,
    });

    userflow.group(storeInfo.id.toString(), {
      posLastSeen: new Date().toISOString(),
      posHasLegacySubscription: hasLegacySubscription,
    });
  }, [
    userflowToken,
    userId,
    storeInfo.id,
    hasLegacySubscription,
    email,
    firstName,
    lastName,
    roles,
  ]);

  return null;
}

function getFullName(
  firstName?: string | null,
  lastName?: string | null,
): string {
  return [firstName, lastName].filter(Boolean).join(" ").trim();
}

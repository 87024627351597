import Big from "big.js";
import { format as formatDate } from "date-fns";
import { Align, Cut, InMemory, Model, Printer, Style } from "escpos-buffer";
import Epson from "escpos-buffer/dist/profile/Epson";
import { sumBy, upperCase } from "lodash";

import getCurrencyInstance from "~/helpers/getCurrencyInstance";
import { getLocaleFieldValue } from "~/helpers/i18n";
import { type Order, type StoreInfo } from "~/types";
import { PRINTER_COLUMNS, profileInfo, Spacing } from "./constants";
import { type TicketTranslator, type TicketType } from "./types";

interface PrinterOptions {
  selectedColumns: number;
  allowSpecialChars: boolean;
  allowStyles: boolean;
}

export default class OrderTicketGenerator {
  private _printer?: Printer;

  private readonly storeInfo: StoreInfo;

  private readonly selectedColumns: number;

  private readonly allowSpecialChars: boolean;

  private readonly allowStyles: boolean;

  private readonly t: TicketTranslator;

  constructor(
    t: TicketTranslator,
    storeInfo: StoreInfo,
    { selectedColumns, allowSpecialChars, allowStyles }: PrinterOptions,
  ) {
    this.t = t;
    this.storeInfo = storeInfo;
    this.selectedColumns = selectedColumns;
    this.allowStyles = allowStyles;
    this.allowSpecialChars = allowSpecialChars;
  }

  get printer() {
    if (!this._printer) {
      throw new Error("In memory printer not initialized");
    }

    return this._printer;
  }

  async connect() {
    const connection = new InMemory();
    const profile = new Epson(profileInfo);
    const model = new Model(profile);
    this._printer = await Printer.CONNECT(model, connection);
    this._printer.setColumns(this.selectedColumns);

    return connection;
  }

  public async getPrinterCommands(
    type: TicketType,
    order: Order,
    { isTestTicket = false },
  ) {
    const connection = await this.connect();

    await this.printHeader();
    await this.printOrderSummary(order);
    await this.printItems(order.products, type);

    if (type === "purchase") {
      await this.printTotals(order);
      await this.printPaymentDetails(order);
    }

    await this.printFooter();

    if (isTestTicket) {
      await this.printColumnTest();
    }

    await this.printer.feed(2);
    this.printer.cutter(Cut.Full);

    return connection.buffer();
  }

  async printCentered(text: string, style?: Style) {
    await this.printer.withStyle({ align: Align.Center }, async () => {
      await this.writeln(text, style);
    });
  }

  async printDivider() {
    await this.writeln(`${"-".repeat(this.selectedColumns)}`);
  }

  async writeln(text: string, style?: Style, alignment?: Align) {
    const printer = this.printer;

    if (!this.allowSpecialChars) {
      text = replaceSpecialChars(text);
    }

    if (!this.allowStyles) {
      style = undefined;
    }

    const widthMultiplier = style && style & Style.DoubleWidth ? 2 : 1;
    const heightMultiplier = style && style & Style.DoubleHeight ? 2 : 1;

    const maxLineLength = printer.columns / widthMultiplier;
    const wrappedText = this.wordWrap(text, maxLineLength);

    for (const line of wrappedText) {
      await printer.withStyle(
        { width: widthMultiplier, height: heightMultiplier, align: alignment },
        async () => {
          await printer.writeln(line || "");
        },
      );
    }
  }

  async printRow(
    leftText: string,
    rightText: string,
    alignment = Spacing.SPACE_BETWEEN,
    truncate = false,
  ) {
    const printer = this.printer;

    const availableSpace = printer.columns - (2 + rightText.length);

    const wrappedText = this.wordWrap(leftText, availableSpace);
    const linesToPrint =
      truncate && wrappedText.length > 3
        ? wrappedText.slice(0, 3)
        : wrappedText;

    const lastLine = linesToPrint.at(-1);

    if (!lastLine) {
      throw Error("Invalid input string");
    }

    for (const line of linesToPrint.slice(0, -1)) {
      await this.writeln(line);
    }

    const spaceBeforeRightText = Math.max(
      printer.columns - (lastLine.length + 2 + rightText.length),
      0,
    );
    const formattedLine =
      alignment === Spacing.SPACE_BETWEEN
        ? [
            lastLine,
            " ".repeat(spaceBeforeRightText),
            " ".repeat(2),
            rightText,
          ].join("")
        : [
            " ".repeat(spaceBeforeRightText),
            lastLine,
            " ".repeat(2),
            rightText,
          ].join("");

    await this.writeln(formattedLine);
  }

  wordWrap(input: string, maxLineLength: number): string[] {
    const inputLines = input.split("\n");
    const outputLines: string[] = [];

    const processWords = (words: string[], currentLine: string): void => {
      if (words.length === 0) {
        if (currentLine.trim().length > 0) {
          outputLines.push(currentLine.trimEnd());
        }
        return;
      }

      const word = words.shift()!;

      if (word.length > maxLineLength) {
        if (currentLine.trim().length > 0) {
          outputLines.push(currentLine.trimEnd());
        }
        processLongWord(word);
      } else if (currentLine.length + word.length <= maxLineLength) {
        processWords(words, `${currentLine}${word} `);
      } else {
        outputLines.push(currentLine.trimEnd());
        processWords(words, `${word} `);
      }
    };

    const processLongWord = (word: string): void => {
      if (word.length <= maxLineLength) {
        outputLines.push(word);
      } else {
        outputLines.push(word.slice(0, maxLineLength));
        processLongWord(word.slice(maxLineLength));
      }
    };

    for (const line of inputLines) {
      processWords(line.split(" "), "");
    }

    return outputLines;
  }

  private async printHeader() {
    const { adminLanguage, country, name } = this.storeInfo;
    const storeName = getLocaleFieldValue(name, `${country}_${adminLanguage}`);

    await this.printCentered(storeName, Style.DoubleHeight | Style.DoubleWidth);
    await this.printDivider();
  }

  private async printOrderSummary(order: Order) {
    const date = new Date(order.created_at);

    const dateString = formatDate(date, "dd/MM/yyyy");
    const timeString = formatDate(date, "HH:mm");

    await this.printRow(dateString, timeString);

    await this.printRow(`${this.t("orderNumber")}:`, `#${order.number}`);

    if (order.contact_name) {
      await this.printRow(`${this.t("customer")}:`, order.contact_name);
    }

    const cashierName = getUserFullName(order.initiated_by);

    if (cashierName) {
      await this.printRow(`${this.t("cashier")}:`, cashierName);
    }

    const sellerName = getUserFullName(order.origin?.sold_by);
    if (sellerName) {
      await this.printRow(`${this.t("seller")}:`, sellerName);
    }

    await this.printDivider();
  }

  private async printItems(products: Order["products"], type: TicketType) {
    await this.printRow(
      upperCase(this.t("product")),
      upperCase(
        type === "exchange"
          ? this.t("itemQuantity")
          : upperCase(this.t("itemTotal")),
      ),
    );

    await this.printDivider();

    for (const product of products) {
      const total = new Big(product.price).mul(product.quantity).toString();

      let productText = product.name;
      if (type === "purchase") {
        productText += `\n${product.quantity} x ${this.formatMoney(
          product.price,
        )}`;
      }

      await this.printRow(
        productText,
        type === "exchange"
          ? product.quantity.toString()
          : this.formatMoney(total),
      );
    }

    await this.printDivider();
  }

  private async printTotals(order: Order) {
    const shippingCost = parseFloat(order.shipping_cost_customer);
    const quantity = sumBy(order.products, (p) =>
      // NOTE: workaround to fix different type in product.quantity between createOrder and getOrder (number vs. string)
      parseInt(p.quantity.toString(), 10),
    );

    await this.printRow(
      `${this.t("subtotal")} (${quantity} ${this.t("products")}):`,
      this.formatMoney(order.subtotal),
    );

    const coupons = order.coupon;
    for (const coupon of coupons) {
      const value = parseFloat(coupon.value);

      const discount =
        coupon.type === "percentage"
          ? new Big(order.subtotal).mul(coupon.value).div(100).toString()
          : coupon.value;

      if (value > 0) {
        await this.printRow(
          `${this.t("discount")} (${
            coupon.type === "percentage"
              ? parseInt(coupon.value, 10)
              : this.formatMoney(coupon.value)
          }${coupon.type === "percentage" ? "%" : ""}):`,
          `-${this.formatMoney(discount)}`,
        );
      }
    }

    if (order.discount_gateway) {
      const discountGateway = order.discount_gateway;
      await this.printRow(
        `${this.t("discountGateway")}:`,
        `-${this.formatMoney(discountGateway)}`,
      );
    }

    if (shippingCost > 0) {
      await this.printRow(
        `${this.t("shipping")}:`,
        this.formatMoney(order.shipping_cost_customer),
      );
    }

    await this.printer.feed();

    await this.printCentered(
      `${upperCase(this.t("total"))} ${this.formatMoney(order.total)}`,
      Style.DoubleHeight | Style.DoubleWidth,
    );

    await this.printDivider();
  }

  private async printPaymentDetails(order: Order) {
    const { method, installments } = order.payment_details;

    if (!method) {
      return;
    }

    await this.printRow(
      `${this.t("payment.paymentMethod")}:`,
      this.t(`payment.methods.${method}`),
    );

    if (method === "credit_card" && Number(installments) > 1) {
      await this.printRow(`${this.t("payment.installments")}:`, installments);
    }

    await this.printDivider();
  }

  private async printFooter() {
    await this.printer.feed();
    await this.printCentered(this.t("footer"));
    await this.printer.feed(2);
  }

  private formatMoney(value: string) {
    return getCurrencyInstance(value, this.storeInfo.mainCurrency).format();
  }

  private async printColumnTest() {
    await this.printDivider();
    await this.writeln(this.t("test.title"));
    await this.printDivider();
    await this.writeln(this.t("test.description"));
    await this.printer.feed();

    for (const colSize of PRINTER_COLUMNS) {
      await this.writeln(`${colSize} ${this.t("test.numberOfCharacters")}`);
      await this.printer.writeln("a".repeat(colSize));
      await this.printer.feed();
    }

    await this.printer.feed(2);
  }
}

const replaceSpecialChars = (text: string) => {
  return text
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[^a-zA-Z0-9 \-.,():$%]/g, "");
};

const getUserFullName = (user?: {
  first_name?: string;
  last_name?: string;
}) => {
  const name = [user?.first_name, user?.last_name].filter((val) => !!val);

  if (!name.length) {
    return undefined;
  }

  return name.join(" ");
};
